// $('#user_login').attr( 'placeholder', 'Email' );
// $('#user_pass').attr( 'placeholder', 'Password' );
// $('#wppb-submit').attr('value', 'SIGN IN');
// $('.login-remember label').hide();
// $('.login-remember').prepend('<span class="forgot-password" style="margin-right: 15px;"><a href="">Forgot your password?</a></span> ');

// if ($.browser.msie && $.browser.version === 10) {
//     $("html").addClass("ie10");
// }


function goBack() {
    window.history.back();
}

  // When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function() {scrollFunction()};

function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById("myBtn").style.display = "block";
    } else {
        document.getElementById("myBtn").style.display = "none";
    }
}

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}





   

